<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }" >
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="name" v-if="user.type === 'client'">{{ $t('customer_.customer_name') }}</label>
          <label for="name" v-if="user.type === 'business'">{{ $t('auth.company_name') }}</label>
          <input type="text" v-model="user.name" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="name" :placeholder="$t('customer_.enter_name')">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
        <div class="form-group">
          <label for="email">{{ $t('auth.email_address') }}</label>
          <input type="email" v-model="user.email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="email" aria-describedby="emailHelp" :placeholder="$t('auth.enter_email')">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider name="Password" rules="required|min:6" v-slot="{ errors }">
        <div class="form-group">
          <label for="password">{{ $t('auth.password') }}</label>
          <input type="password" v-model="user.password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="password" :placeholder="$t('auth.password')">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider name="RepeatPassword" rules="required|confirmed:Password" v-slot="{ errors }">
        <div class="form-group">
          <label for="password">{{ $t('auth.repeat_password') }}</label>
          <input type="password" v-model="user.repeat_password" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="repeat_password" :placeholder="$t('auth.repeat_password')">
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <ValidationProvider name="Terms and conditions" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
      <div class="form-group">
        <input type="checkbox" value="" v-model="terms" id="terms" :error-messages="errors"/>
        <label for="terms">{{ $t('auth.i_accept') }} <a href="https://12booky.com/terms-of-service" target="_blank">{{ $t('auth.terms_and_conditions') }}</a></label>
        <div class="invalid-feedback" style="display:block">
          <span>{{ errors[0] }}</span>
        </div>
      </div>
      </ValidationProvider>

      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">{{ $t('auth.sign_up') }}</button>
      </div>
      <div v-if="apiErrors" style="color:red; margin-top:10px">{{ $t('feature.user_already_exists') }}</div>
      <div class="sign-info">
          <span class="dark-color d-inline-block line-height-2">
            {{ $t('auth.already_account') }}
            <router-link to="/auth/login" class="iq-waves-effect pr-4">
                {{ $t('auth.sign_in') }}
              </router-link>
          </span>
          <social-login-form :formtype="user.type"></social-login-form>
      </div>
    </form>
    <component :is="`style`" v-if="user.type === 'client'">
      .sign-in-12booky{
        background-color: #47cbff;
        background-position:top right;
        background-repeat: no-repeat;
        background-size: 30%;
      }
    </component>
    <component :is="`style`" v-if="user.type === 'business'">
      .sign-in-12booky{
      background-color: #63c9c7;
      background-position:top right;
      background-repeat: no-repeat;
      background-size: 40%;
      }
    </component>

    <component :is="`style`" v-if="user.type === 'business' || user.type === 'client'">
      .sign-in-12booky h1,
      .sign-in-12booky label,
      .sign-in-12booky span
      { color: #fff !important; }
      .sign-in-12booky input[type="text"],
      .sign-in-12booky input[type="email"],
      .sign-in-12booky input[type="password"] {
        background: #fff !important;
      }
      @media only screen and (max-width: 830px) {
        .sign-in-12booky{
          background-position:top left;
          background-size: 180px;
        }
      }
      .sign-in-12booky h1{
      margin-top: 100px !important;
      }
    </component>
  </ValidationObserver>
</template>

<script>
import SocialLoginForm from './SocialLoginForm'
import { mapActions, mapGetters } from 'vuex'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'SignUpForm',
  components: { SocialLoginForm },
  props: ['formType'],
  computed: {
    ...mapGetters({
      users: 'Setting/usersState'
    })
  },
  data: () => ({
    user: {
      name: '',
      email: '',
      password: '',
      type: 'business'
    },
    terms: '',
    apiErrors: '',
    device: localStorage.getItem('fcm') || ''
  }),
  methods: {
    ...mapActions({
      signupCompany: 'Company/signup',
      signupCustomer: 'Customer/signup'
    }),
    onSubmit () {
      this.apiErrors = ''
      if (this.user.password.length > 0) {
        const dataValues = {
          name: this.user.name,
          email: this.user.email,
          password: this.user.password,
          device: this.device
        }
        if (this.user.type === 'business') {
          this.signupCompany(dataValues)
            .then((response) => {
              console.log(response)
              if (response.data.message === 'exists') {
                this.apiErrors = true
                return
              }
              const theUser = JSON.parse(response.data.user)
              if (this.device !== '') {
                const postData = {
                  token: this.device,
                  customer: theUser.token
                }
                Axios.post(API.API_STORE_TOKEN, postData)
                  .then(() => {
                    console.log('Push registration success')
                  })
                  .catch(() => {
                  })
              }
              if (this.$route.fullPath === '/auth/signup/plan/1') {
                this.$router.push('/app/buyplan/1')
              } else if (this.$route.fullPath === '/auth/signup/plan/2') {
                this.$router.push('/app/buyplan/2')
              } else if (this.$route.fullPath === '/auth/signup/plan/3') {
                this.$router.push('/app/buyplan/3')
              } else if (this.$route.fullPath === '/auth/signup/plan/4') {
                this.$router.push('/app/buyplan/4')
              } else {
                this.$router.push('/app/company')
              }
            })
            .catch((response) => {
              console.log('errror2', response)
              this.apiErrors = this.$store.getters['Company/theError']
            })
        } else {
          this.signupCustomer(dataValues)
            .then((response) => {
              console.log(response)
              if (response.data.message === 'exists') {
                this.apiErrors = true
                return
              }
              const theUser = JSON.parse(response.data.user)
              if (this.device !== '') {
                const postData = {
                  token: this.device,
                  customer: theUser.token
                }
                Axios.post(API.API_STORE_TOKEN, postData)
                  .then(() => {
                    console.log('Push registration success')
                  })
                  .catch(() => {
                  })
              }
              this.$router.push('/customer/profile')
            })
            .catch((response) => {
              console.log('errror2', response)
              this.apiErrors = this.$store.getters['Customer/theError']
            })
        }
      }
    },
    changeSwitch () {
      if (this.user.type === 'client') {
        this.user.type = 'business'
      } else {
        this.user.type = 'client'
      }
    }
  }
}
</script>
<style>
label[for="switch"] {
  width: 300px;
  background-color: rgba(0,0,0,.1);
  border-radius: 100px;
  position: relative;
  margin-top: 10px;
  cursor: pointer;
  height: 2.8rem;
}
.toggle {
  position: absolute;
  width: 150px;
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
  box-shadow: 0 2px 15px rgba(0,0,0,.15);
  transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  height: 2.8rem;
  border-radius: 100px;
}
.names {
  font-size: 15px;
  font-weight: bolder;
  width: 300px;
  margin-top: 3%;
  position: absolute;
  user-select: none;
}
.dark {
  opacity: .5;
}

#switch {
  display: none;
}
/* Toggle */
#switch:checked +  label .toggle{
  transform: translateX(100%);
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%);
}
#switch:checked + label .dark{
  opacity: 1;
  color: #fff !important;
}
#switch:checked + label .light{
  opacity: .5;
}

</style>
